<template>
<div class="contatti">
  <!--Form Contatti-->
  <div class="relative bg-white">
    <div class="absolute inset-0">
      <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
    </div>
    <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
      <div class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div class="max-w-lg mx-auto">
          <h2 class="text-2xl font-extrabold tracking-tight text-cyan-400 sm:text-3xl">
            Contattaci
          </h2>
          <p class="mt-3 text-lg leading-6 text-coolGray-700">
            Per qualsiasi informazione non esistare a contattarci. Ti risponderemo nel più breve tempo possibile.
          </p>
          <dl class="mt-8 text-base text-coolGray-700">
            <div>
              <dt class="sr-only">Indirizzo</dt>
              <dd>
                <p>TechNic di Nicola Berti</p>
                <p>Via alla Sega, 6</p>
                <p>38067 - Ledro (TN)</p>
              </dd>
            </div>
            <div class="mt-6">
              <dt class="sr-only">Cellulare</dt>
              <dd class="flex">
                <PhoneIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span class="ml-3">
                  (+39) 342 1364246
                </span>
              </dd>
            </div>
            <div class="mt-3">
              <dt class="sr-only">Fisso</dt>
              <dd class="flex">
                <PhoneIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span class="ml-3">
                  (+39) 0464 350693
                </span>
              </dd>
            </div>
            <div class="mt-3">
              <dt class="sr-only">Email</dt>
              <dd class="flex">
                <MailIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span class="ml-3">
                  info@tech-nic.it
                </span>
              </dd>
            </div>
            <div class="mt-3">
              <dt class="sr-only">PEC</dt>
              <dd class="flex">
                <MailIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span class="ml-3">
                  tech-nic@pec.it
                </span>
              </dd>
            </div>
          </dl>          
        </div>
      </div>
      <div class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
        <div class="max-w-lg mx-auto lg:max-w-none">
          <form ref="invia" v-on:submit="sendEmail" class="grid grid-cols-1 gap-y-6">
            <div>
              <label for="full-name" class="sr-only">Nome e Cognome</label>
              <input type="text" name="name" id="name" v-model="name" autocomplete="name" class="block w-full shadow-sm py-3 px-4 placeholder-coolGray-500 focus:ring-cyan-500 focus:border-cyan-500 border-coolGray-700 rounded-md" placeholder="Nome e cognome" />
            </div>
            <div>
              <label for="email" class="sr-only">Email</label>
              <input id="email" name="email" type="email" v-model="email" autocomplete="email" class="block w-full shadow-sm py-3 px-4 placeholder-coolGray-500 focus:ring-cyan-500 focus:border-cyan-500 border-coolGray-700 rounded-md" placeholder="Email" />
            </div>
            <div>
              <label for="phone" class="sr-only">Telefono</label>
              <input type="text" name="phone" id="phone" v-model="phone" autocomplete="phone" class="block w-full shadow-sm py-3 px-4 placeholder-coolGray-500 focus:ring-cyan-500 focus:border-cyan-500 border-coolGray-700 rounded-md" placeholder="Telefono" />
            </div>
            <div>
              <label for="message" class="sr-only">Messaggio</label>
              <textarea id="message" name="message" v-model="message" rows="4" class="block w-full shadow-sm py-3 px-4 placeholder-coolGray-500 focus:ring-cyan-500 focus:border-cyan-500 border coolGray-7-gray-300 rounded-md" placeholder="Messaggio" />
            </div>
            <div>
              <button type="submit" value="Send" class="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-coolGray-900 hover:bg-coolGray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                Invia
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!---->
  <div class="bg-white">
    <!-- Header -->
    <div class="relative pb-32 sm:pb-16 bg-coolGray-900">      
      <div class="relative max-w-7xl mx-auto py-12 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">I nostri servizi</h1>
        <p class="mt-6 max-w-3xl text-l lg:text-xl text-white ">Progettazione e relizzazione di:</p>
        <ul class="list-disc list-inside text-l lg:text-xl text-white py-2">
          <li>Impianti di automazione</li>
          <li>Impianti di domotica e videosorveglianza</li>
          <li>Reti cablate e wireless</li>
        </ul> 
        <p class="mt-6 max-w-3xl text-l lg:text-xl text-white ">Sviluppo di software PLC e/o applicativi in c#, python o js</p>
        <p class="mt-6 max-w-3xl text-l lg:text-xl text-white ">Sviluppo di applicativi custom per l'interfacciamento di impianti nell'ottica dell'incentivo Industria 4.0</p>       
      </div>
    </div>

    <!-- Overlapping cards -->
    <section class="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
      <h2 class="sr-only" id="contact-heading">Contact us</h2>
      <div class="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
        <div v-for="link in supportLinks" :key="link.name" class="flex flex-col bg-white rounded-2xl shadow-xl">
          <div class="flex-1 relative pt-16 px-6 pb-8 md:px-8">
            <div class="absolute top-0 p-5 inline-block bg-cyan-400 rounded-xl shadow-lg transform -translate-y-1/2">
              <component :is="link.icon" class="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <h3 class="text-xl font-medium text-coolGray-900">{{ link.name }}</h3>
            <p class="mt-4 text-base text-coolGray-500">{{ link.description }}</p>
          </div>          
        </div>
      </div>
    </section>
  </div>

</div>
</template>

<script>
import { MailIcon, PhoneIcon, NewspaperIcon, SupportIcon, InformationCircleIcon} from '@heroicons/vue/outline'
//Import della api emailjs per madare le mail (Limite di 200 al mese per piano gratis, se ci si avvicina alla soglia vedere cosa implementare)
import emailjs from 'emailjs-com'

const supportLinks = [
  {
    name: 'Supporto tecnico',
    description:
      'Supporto tecnico per qualsiasi problematica legata ad impianti automazzati e robotizzati o in ambito IT.',
    icon: SupportIcon,
  },
  {
    name: 'Info',
    description:
      'Contattateci per qualsiasi informazione. ',
    icon: InformationCircleIcon,
  },  
  {
    name: 'Preventivi',
    description:
      'Non esitare a contattarci per richiedere un preventivo per qualsiasi lavoro in cui le nostre competenze possano fare la differenza.',
    icon: NewspaperIcon,
  },
]

export default {
  components: {
    MailIcon,
    PhoneIcon,
  },
  setup() {
    return {
      supportLinks,
    }
  },
  methods:{
    //Funzione per l'invio della mail e la pulizia dei campi una volta inviata la mail
    sendEmail(e) {
      try {
        emailjs.sendForm('service_xg5ders', 'template_ch23h8x', this.$refs.invia, 'user_jl2FDHX5KTrAczpDwo5RG', {
          name: this.name,
          email: this.email,
          message: this.message,
          phone: this.phone,
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
      this.phone = ''
    },  
  },
}
</script>
